import { PageProps } from 'gatsby'

import React, { FC } from 'react'

import { withPage } from '~/components/page'
import PersonDetailView from '~/views/personDetail'

interface Props extends PageProps {
	params: {
		id: string
	}
}

const PersonDetailPage: FC<Props> = props => {
	return <PersonDetailView personId={props.params.id} />
}

export default withPage(PersonDetailPage, {
	public: false,
	name: 'Person Details',
})
